module.exports = {
    plugins: [
    {
      resolve: `gatsby-plugin-google-gtag`,
      options: {
        // You can add multiple tracking ids and a pageview event will be fired for all of them.
        trackingIds: [
          "G-QSZWEQ7KTE", // Google Analytics / GA
        ],
        // This object gets passed directly to the gtag config command
        // This config will be shared across all trackingIds
        gtagConfig: {
          optimize_id: "OPT_CONTAINER_ID",
          anonymize_ip: false,
          cookie_expires: 0,
        },
        // This object is used for configuration specific to this plugin
        pluginConfig: {
          // Puts tracking script in the head instead of the body
          head: false,
          // Setting this parameter is also optional
          respectDNT: true,
        },
      },
    },
  ],

  //-- SITE SETTINGS -----
  author: "@germanheim",
  siteTitle: "Germán",
  siteShortTitle: "Germán.", // Used as logo text in header, footer, and splash screen
  siteDescription:
    "Pagina web personal de Germán Heim 👨‍🔬.",
  siteUrl: "https://www.germanheim.com",
  siteLanguage: "es_AR",
  siteIcon: "content/favicon.png", // Relative to gatsby-config file
  seoTitleSuffix: "Sitio Web", // SEO title syntax will be e.g. "Imprint - {seoTitleSuffix}"
  useCookieBar: true, // If you use Google Analytics and want to be GDPR-compliant, set it to true
  googleAnalyticsTrackingId: "UA-199933169-1", // e.g. UA-XXXXXX-X
  // -- THEME SETTINGS -----
  colors: {
    lightTheme: {
      primary: "#000000",
      secondary: "#FFF4D9",
      tertiary: "#F2F2F2",
      text: "#000000",
      subtext: "#555555",
      background: "#FFFFFF",
      card: "#FFFFFF",
      scrollBar: "rgba(0, 0, 0, 0.5)",
      boxShadow: "rgba(0, 0, 0, 0.16)",
      boxShadowHover: "rgba(0, 0, 0, 0.32)",
    },
    darkTheme: {
      primary: "#FAFAFA",
      secondary: "#e7a800",
      tertiary: "#252525",
      text: "rgba(255, 255, 255, 0.87)",
      subtext: "#AAAAAA",
      background: "#121212",
      card: "#1C1C1C",
      scrollBar: "rgba(255, 255, 255, 0.5)",
      boxShadow: "rgba(0, 0, 0, 0.16)",
      boxShadowHover: "rgba(0, 0, 0, 0.32)",
    },
  },
  fonts: {
    primary: "Roboto, Arial, sans-serif",
  },
  //-- SOCIAL MEDIA SETTINGS -----
  socialMedia: [
    {
      name: "Instagram",
      url: "https://www.instagram.com/german_heim/",
    },
    {
      name: "GitHub",
      url: "https://github.com/germanheim/",
    },
    {
      name: "Goodreads",
      url: "https://www.goodreads.com/user/show/79866487-germ-n-heim",
    },
    {
       name: "Mail",
       url: "mailto:contacto@germanheim.com",
    },
  ],
  //-- NAVIGATION SETTINGS -----
  navLinks: {
    menu: [
      {
        name: "Sobre Mi",
        url: "/#sobremi",
      },
      {
        name: "Proyectos",
        url: "/#proyectos",
      },
    ],
    button: {
      useFileName: false,
      name: "Contacto",
      fileName: "", // the file has to be placed inside the static folder at the root level
      url: "/#contacto", // if useFileName=false, you can set an anchor link here and use the button for navigational purposes
    },
  },
  footerLinks: [
    {
      name: "Privacidad",
      url: "/privacy",
    },
  ],
}
