import { sharedPreferences } from "../styles/theme"

// breakpoints.lg needs to be cleaned and parsed since it is a string like '1200px'
export const detectMobileAndTablet = windowWidth =>
  windowWidth <
  parseInt(sharedPreferences.breakpoints.lg.match(/\d+/gi).join(""))

// detect server-side-rendering to fix bugs while gatsby build
export const isSSR = typeof window === "undefined"

// used to parse the publication date of medium articles
export const parseDate = date => {
  const year = date.substring(0, 4)
  const month = date.substring(5, 7)
  const day = date.substring(8, 10)

  switch (month) {
    case "01":
      return day + " Enero " + year
    case "02":
      return day + " Febrero " + year
    case "03":
      return day + " Marzo " + year
    case "04":
      return day + " Abril " + year
    case "05":
      return day + " Mayo " + year
    case "06":
      return day + " Junio " + year
    case "07":
      return day + " Julio " + year
    case "08":
      return day + " Agosto " + year
    case "09":
      return day + " Septiembre " + year
    case "10":
      return day + " Octubre " + year
    case "11":
      return day + " Noviembre " + year
    case "12":
      return day + " Diciembre " + year
    default:
      return "Sin fecha de publicación"
  }
}
